<template>
  <download-excel
    :class="eClass"
    :fetch="fetchFile"
    :fields="fileFields"
    :before-generate="fileStart"
    :before-finish="fileFinish"
    :name="fileName + '.' + ext"
    :title="fileTitle"
    :worksheet="worksheetTitle"
    :type="ext"
  >
    <span :title="'Download Participants (' + ext.toUpperCase() + ')'" v-html="btnLabel"></span>
  </download-excel>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'EventParticipantsExport',
  props: {
    eClass: {
      type: String,
      default () { return 'btn btn-light' }
    },
    btnLabel: {
      type: String,
      default () { return '<i class="fa fa-download"></i>' }
    },
    ext: {
      type: String,
      default () { return 'xls' }
    },
    eid: {
      type: Number,
      required: true
    }
  },
  data () {
    let vm = this
    return {
      fileName: 'nzs-event-participants',
      fileTitle: 'Event Participants',
      fileFields: {
        'Participant': 'participant.fullName',
        'Age': {
          field: 'participant.dob',
          callback (dob) {
            return vm.$moment().diff(dob, 'years')
          }
        },
        'DOB': {
          field: 'participant.dob',
          callback (dob) {
            return vm.$moment(dob).format('MM/DD/YYYY')
          }
        },
        'Gender': 'participant.gender',
        // 'Questions': 'questions',
        'Date': {
          field: 'datetime',
          callback (datetime) {
            return vm.$moment(datetime).format('MM/DD/YY')
          }
        },
        'Member': 'user.fullName',
        'Phone': 'user.phone',
        'Email': 'user.email',
        'Zip Code': 'user.zipCode',
        'Requests': 'request',
        'Coach': 'coachVolunteer',
        'Amount Due': {
          field: 'invoice.amountDue',
          callback (amountDue) {
            return amountDue.toLocaleString("en-US", {style:"currency", currency:"USD"})
          }
        },
        'Paid': {
          field: 'invoice.transactionId',
          callback (transactionId) {
            return transactionId ? 'Yes' : 'No'
          }
        },
        'Transaction ID': 'invoice.transactionId',
        'Admin Notes': 'notes',
        // 'Questions': 'questions'
      }
    }
  },
  computed: {
    worksheetTitle () {
      return this.fileTitle
    }
  },
  methods: {
    ...mapActions([
      'getEvent',
      'getUser',
      'getEventRegistrations',
      'getEventQuestions'
    ]),
    setFileField (label, key, index) {
      // this.fileFields[label] = key
      // console.log(label, key)
      // Create a temp object and index variable
      var obj = { ...this.fileFields }
      var temp = {}
      var i = 0
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (i === index && key && label) {
            temp[label] = key
          }
          temp[prop] = obj[prop]

          i++
        }
      }
      if (!index && key && label) {
        temp[label] = key
      }
      this.fileFields = temp
    },
    async fetchFile () {
      this.showMessage({ title: 'Loading', message: 'Just a moment while we load the data...' })
      let ret = [],
      eventQuestions = []

      // get event registrations
      await this.getEventRegistrations({
        event: this.eid,
        pagination: false
      }).then(resp => {
        this.fileTitle = resp[0].event.title + ' Participants'
        this.fileName = resp[0].event.title
        .toLowerCase().replace(/\s/g,'-').replace(/[^0-9a-z_-]/g,'')
        ret = resp
      })

      if (!ret.length) {
        return ret
      }

      let questionIds = []
      await this.getEvent({
        id: this.eid
      }).then((resp) => {
        questionIds = resp.questions.map(e => (e.id))
      })

      // get question IDs
      // let questionIds = Object.keys(ret[0].questionResponses)

      // cycle through question IDs
      // get question & response data
      await this.getEventQuestions({id: questionIds}).then(resp => {
        for (let i in resp) {
          // add question & key to this.fileField
          let questionKey = 'responseTo' + resp[i].label.replace(/[^a-z0-9]/ig, '')
          // questionKey = 'questionResponses-' + questionKey.charAt(0).toLowerCase() + questionKey.substring(1)
          resp[i].formattedKey = questionKey
          this.setFileField(resp[i].label, questionKey, 3)
        }
        eventQuestions = resp
      })

      // iterate ret - assign relative val to each question's response
      for (let r in ret) {
        // iterate questionResponses
        let questionResponses = ret[r].questionResponses
        for (let qr in questionResponses) {
          // iterate eventQuestions
          for (let eq in eventQuestions) {
            // ret[r][eventQuestions[eq].formattedKey] = null
            if (eventQuestions[eq].id == qr && !eventQuestions[eq].options.length) {
              // question matches
              // answer in text format
              ret[r][eventQuestions[eq].formattedKey] = questionResponses[qr]
            } else if (eventQuestions[eq].id == qr) {
              // question matches
              // iterate eventQuestions response options
              for (let o in eventQuestions[eq].options) {
                if (eventQuestions[eq].options[o].id == questionResponses[qr]) {
                  // option matches
                  let val = eventQuestions[eq].options[o].label.replace(/[^a-z0-9\s-\(\)]/ig, '')
                  ret[r][eventQuestions[eq].formattedKey] = val
                  // console.log('ret['+r+']['+eventQuestions[eq].formattedKey+']', val)
                }
              }
            }
          }
        }
      }

      // console.log('ret: ', ret)
      return ret
    },
    fileStart () {},
    fileFinish () {}
  }
}
</script>